import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { useAppDispatch, useAppSelector } from '../hooks';

type PopupState = {
  openPopupItemId: number | null;
};

const initialState: PopupState = {
  openPopupItemId: null,
};

export const popupSlice = createSlice({
  name: 'popup',
  initialState,
  reducers: {
    openPopup: (state, action: PayloadAction<number>) => {
      state.openPopupItemId = action.payload;
    },
    closePopup: (state) => {
      state.openPopupItemId = null;
    },
  },
});

const { openPopup, closePopup } = popupSlice.actions;

export const usePopup = () => {
  const { openPopupItemId } = useAppSelector((state) => state.popupReducer);
  const dispatch = useAppDispatch();

  const setOpenId = (openPopupItemId: number) => {
    dispatch(openPopup(openPopupItemId));
  };

  const close = () => {
    dispatch(closePopup());
  };

  return {
    openPopupItemId,
    setOpenId,
    close,
  };
};
