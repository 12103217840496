import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import { ListResponse } from '../../pb/api/v1/user/item/item_pb';
import type { RootState } from '../store';

export type ListResponseObject = {
  searchItemList: ListResponse.Item.AsObject[] | undefined;
};

const initialState: ListResponseObject = {
  searchItemList: undefined,
};

export const listResponseSlice = createSlice({
  name: 'listResponse',
  initialState,
  reducers: {
    setSearchItemList: (state, action: PayloadAction<ListResponse.Item.AsObject[]>) => {
      state.searchItemList = action.payload;
    },
  },
});

export const { setSearchItemList } = listResponseSlice.actions;

export const selectListResponse = (state: RootState) => state.listResponseReducer;

export default listResponseSlice.reducer;
