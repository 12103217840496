import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { CreditCard } from '../../api/userService';

import { User } from '@pb/api/v1/user/common_pb';

interface StateProps {
  user?: User.AsObject | null;
  creditCard?: CreditCard | null;
  isLogin?: boolean;
}

export const initialState: StateProps = {
  user: null,
  creditCard: null,
  isLogin: false,
};

export const userSlice = createSlice({
  name: 'notionsSlice',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<StateProps>) => {
      state.user = action.payload.user || null;
    },
    setCreditCard: (state, action: PayloadAction<StateProps>) => {
      state.creditCard = action.payload.creditCard || null;
    },
    setIsLogin: (state, action: PayloadAction<{ isLogin: boolean }>) => {
      state.isLogin = action.payload.isLogin || false;
    },
  },
});

export const { setUser, setCreditCard, setIsLogin } = userSlice.actions;
export default userSlice;
