import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ShippingExpectTime } from '../../pb/api/v1/common/common_pb';

type State = {
  shippingAddressId?: number;
  shippingDate?: string;
  expectTimeCode: number;
};

export const initialState: State = {
  shippingAddressId: undefined,
  shippingDate: undefined,
  expectTimeCode: ShippingExpectTime.Time.NOTIMEZONESPECIFIED,
};

export const purchaseFlowSlice = createSlice({
  name: 'addressFormState',
  initialState,
  reducers: {
    setShippingAddressId: (state, action: PayloadAction<number>) => {
      state.shippingAddressId = action.payload;
    },
    setShippingDate: (state, action: PayloadAction<State['shippingDate']>) => {
      state.shippingDate = action.payload;
    },
    setExpectTimeCode: (state, action: PayloadAction<State['expectTimeCode']>) => {
      if (action.payload === 0) {
        console.error('Invalid update');
        return state;
      }
      state.expectTimeCode = action.payload;
    },
    initAddressForm: (state) => {
      state.shippingDate = initialState.shippingDate;
      state.expectTimeCode = initialState.expectTimeCode;
    },
    initPurchaseFlowState: () => {
      return initialState;
    },
  },
});

export const {
  setShippingAddressId,
  setShippingDate,
  setExpectTimeCode,
  initAddressForm,
  initPurchaseFlowState,
} = purchaseFlowSlice.actions;

export default purchaseFlowSlice;
