import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { useAppDispatch, useAppSelector } from '../hooks';

import { useUser } from '@apis/account/useUser';

export type CartParam = {
  itemId: number;
  itemCount: number;
};

type State = {
  cartParams: Record<string, CartParam[]>; // key: email, value: CartParam[]
  orderShopId?: number;
};

export const initialState: State = { cartParams: {} };

export const cartParamSlice = createSlice({
  name: 'registerCartParam',
  initialState,
  reducers: {
    addCartParam: (state, action: PayloadAction<{ email: string; cartParam: CartParam }>) => {
      const { email, cartParam } = action.payload;
      if (!state.cartParams[email]) {
        state.cartParams[email] = [];
      }

      // if not exist same itemId
      if (!state.cartParams[email].some((cp) => cp.itemId === cartParam.itemId)) {
        state.cartParams[email].push(cartParam);
      } else {
        // if exist same itemId, update the count
        state.cartParams[email] = state.cartParams[email].map((cp) =>
          cp.itemId === cartParam.itemId
            ? { itemId: cp.itemId, itemCount: cp.itemCount + cartParam.itemCount }
            : cp
        );
      }
    },
    updateItemCount: (state, action: PayloadAction<{ email: string; cartParam: CartParam }>) => {
      const { email, cartParam } = action.payload;
      if (state.cartParams[email]) {
        state.cartParams[email] = state.cartParams[email].map((cp) =>
          cp.itemId === cartParam.itemId ? cartParam : cp
        );
      }
    },
    updateCartParams: (
      state,
      action: PayloadAction<{ email: string; cartParams: CartParam[] }>
    ) => {
      const { email, cartParams } = action.payload;
      state.cartParams[email] = cartParams;
    },
    deleteCartParam: (state, action: PayloadAction<{ email: string; itemId: number }>) => {
      const { email, itemId } = action.payload;
      if (state.cartParams[email]) {
        state.cartParams[email] = state.cartParams[email].filter((cp) => cp.itemId != itemId);
      }
    },
    initCartParams: (state, action: PayloadAction<string>) => {
      const email = action.payload;
      state.cartParams[email] = [];
    },
    setOrderShopId: (state, action: PayloadAction<number | undefined>) => {
      return { ...state, orderShopId: action.payload };
    },
    migrateCartParam: (state, action: PayloadAction<string>) => {
      const email = action.payload;
      if (state.cartParams['anonymous']) {
        if (!state.cartParams[email]) {
          state.cartParams[email] = [];
        }
        state.cartParams['anonymous'].forEach((anonCartParam) => {
          if (state.cartParams[email].some((cp) => cp.itemId === anonCartParam.itemId)) {
            // if the same itemId exists, update the count
            state.cartParams[email] = state.cartParams[email].map((cartParam) =>
              cartParam.itemId === anonCartParam.itemId
                ? { ...cartParam, itemCount: anonCartParam.itemCount }
                : cartParam
            );
          } else {
            // if not exist same itemId, add it
            state.cartParams[email].push(anonCartParam);
          }
        });
        // remove anonymous cartParams
        delete state.cartParams['anonymous'];
      }
    },
  },
});

const actions = cartParamSlice.actions;

export const useCartParams = () => {
  const user = useUser();
  const email = user?.email || 'anonymous';
  const cartParams = useAppSelector((state) => state.userCarts.cartParams[email] || []);
  const orderShopId = useAppSelector((state) => state.userCarts.orderShopId);
  const dispatch = useAppDispatch();

  const addCartParam = (itemId: number, itemCount: number) =>
    dispatch(actions.addCartParam({ email, cartParam: { itemId, itemCount } }));
  const updateCartParams = (cartParams: CartParam[]) =>
    dispatch(actions.updateCartParams({ email, cartParams }));
  const deleteCartParam = (itemId: number) => dispatch(actions.deleteCartParam({ email, itemId }));
  const initCartParams = () => dispatch(actions.initCartParams(email));
  const setOrderShopId = (orderShopId?: number) => dispatch(actions.setOrderShopId(orderShopId));
  const updateItemCount = (cartParam: CartParam) =>
    dispatch(actions.updateItemCount({ email, cartParam }));
  const migrateCartParam = (email: string) => dispatch(actions.migrateCartParam(email));

  return {
    cartParams,
    orderShopId,
    setOrderShopId,
    addCartParam,
    updateCartParams,
    deleteCartParam,
    initCartParams,
    updateItemCount,
    migrateCartParam,
  };
};
