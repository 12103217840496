import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Provider } from 'react-redux';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';

import { store } from './redux/store';

export default ({ element }: { element: JSX.Element | JSX.Element[] }) => {
  const queryClient = new QueryClient();
  const persistor = persistStore(store);

  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <PersistGate loading={element} persistor={persistor}>
          {element}
        </PersistGate>
      </Provider>
      <ReactQueryDevtools />
    </QueryClientProvider>
  );
};
