import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Purchaser } from '../../api/userService';

interface StateProps {
  eGiftCard?: {
    receiver: string;
    sender: string;
    message: string;
    templateIndex: number;
  };
  purchaser?: Purchaser;
  voucher?: string;
  publish?: boolean;
  delivery?: string;
}

export const initialState: StateProps = {
  eGiftCard: {
    receiver: '',
    sender: '',
    message: '',
    templateIndex: 0,
  },
  purchaser: {
    id: '',
    email: '',
    first_name: '',
    prefecture: '',
    building: '',
    last_name: '',
    first_kana: '',
    last_kana: '',
    address: '',
    postcode: '',
    tel: '',
  },
  voucher: 'anonymous',
  publish: false,
  delivery: 'enabled',
};

export const eGiftCardSlice = createSlice({
  name: 'eGiftCardSlice',
  initialState,
  reducers: {
    setEGiftCard: (state, action: PayloadAction<StateProps>) => {
      state.eGiftCard = action.payload.eGiftCard || {
        receiver: '',
        sender: '',
        message: '',
        templateIndex: 0,
      };
    },
    setPurchaser: (state, action: PayloadAction<StateProps>) => {
      state.purchaser = action.payload.purchaser;
    },
    setVoucher: (state, action: PayloadAction<StateProps>) => {
      state.voucher = action.payload.voucher || 'anonymous';
    },
    setPublish: (state, action: PayloadAction<StateProps>) => {
      state.publish = action.payload.publish || false;
    },
    setDelivery: (state, action: PayloadAction<StateProps>) => {
      state.delivery = action.payload.delivery || 'enabled';
    },
  },
});

export const { setEGiftCard, setVoucher, setPublish, setDelivery, setPurchaser } =
  eGiftCardSlice.actions;

export default eGiftCardSlice;
