import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import { ListRequest } from '../../pb/api/v1/user/item/item_pb';
import type { RootState } from '../store';

export const initialState: ListRequest.AsObject = {
  pagination: { page: 1, perPage: 999 },
  keyword: '',
  price: { first: 0, last: 9999999 },
  allergyList: [],
  particularList: [],
  tasteList: [],
};

export const listRequestSlice = createSlice({
  name: 'listRequest',
  initialState,
  reducers: {
    setListRequest: (_, action: PayloadAction<ListRequest.AsObject>) => action.payload,
    setKeyword: (state, action: PayloadAction<string>) => {
      state.keyword = action.payload;
    },
    setMinPrice: (state, action: PayloadAction<number>) => {
      state.price = { first: action.payload, last: state.price!.last };
    },
    setMaxPrice: (state, action: PayloadAction<number>) => {
      state.price = { first: state.price!.first, last: action.payload };
    },
    setAllergyList: (state, action: PayloadAction<string[]>) => {
      state.allergyList = action.payload;
    },
    setParticularList: (state, action: PayloadAction<string[]>) => {
      state.particularList = action.payload;
    },
    setTasteList: (state, action: PayloadAction<string[]>) => {
      state.tasteList = action.payload;
    },
  },
});

export const {
  setListRequest,
  setKeyword,
  setMinPrice,
  setMaxPrice,
  setAllergyList,
  setParticularList,
  setTasteList,
} = listRequestSlice.actions;

export const selectListRequest = (state: RootState) => state.listRequestReducer;

export default listRequestSlice;
