import React from 'react';
import wrapWithProvider from './src/wrap-with-provider';
import { GatsbyBrowser } from 'gatsby';
import './src/styles/global.css';
import './src/styles/fonts/Hiragino Kaku/hiragino.css';
import './src/styles/fonts/Inter Web/inter.css';
import './src/styles/fonts/Oceanwide/oceanwide.css';
import 'slick-carousel';

export const wrapRootElement = wrapWithProvider;

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({ element }) => {
  return element;
};

export const shouldUpdateScroll = ({ routerProps: { location } }) => {
  const { pathname } = location;

  const pathsToKeepScroll = [
    '^/mypage/basic-info(/.*)?', // Match '/mypage/basic-info' and all of its sub-paths
  ];

  const isTransitionToKeepScroll = pathsToKeepScroll.some((pattern) =>
    new RegExp(pattern).test(pathname)
  );

  if (isTransitionToKeepScroll) {
    return false;
  }

  return true;
};
