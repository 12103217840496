// source: api/v1/user/common.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var api_v1_common_common_pb = require('../../../api/v1/common/common_pb.js');
goog.object.extend(proto, api_v1_common_common_pb);
goog.exportSymbol('proto.tasty.api.v1.user.FavoriteItem', null, global);
goog.exportSymbol('proto.tasty.api.v1.user.FreshnessDate', null, global);
goog.exportSymbol('proto.tasty.api.v1.user.Image', null, global);
goog.exportSymbol('proto.tasty.api.v1.user.Item', null, global);
goog.exportSymbol('proto.tasty.api.v1.user.ItemReview', null, global);
goog.exportSymbol('proto.tasty.api.v1.user.OptionPacking', null, global);
goog.exportSymbol('proto.tasty.api.v1.user.Order', null, global);
goog.exportSymbol('proto.tasty.api.v1.user.ShippingAddress', null, global);
goog.exportSymbol('proto.tasty.api.v1.user.Shop', null, global);
goog.exportSymbol('proto.tasty.api.v1.user.User', null, global);
goog.exportSymbol('proto.tasty.api.v1.user.UserAddress', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.tasty.api.v1.user.Order = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.tasty.api.v1.user.Order, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.tasty.api.v1.user.Order.displayName = 'proto.tasty.api.v1.user.Order';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.tasty.api.v1.user.ItemReview = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.tasty.api.v1.user.ItemReview, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.tasty.api.v1.user.ItemReview.displayName = 'proto.tasty.api.v1.user.ItemReview';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.tasty.api.v1.user.Shop = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.tasty.api.v1.user.Shop, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.tasty.api.v1.user.Shop.displayName = 'proto.tasty.api.v1.user.Shop';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.tasty.api.v1.user.OptionPacking = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.tasty.api.v1.user.OptionPacking, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.tasty.api.v1.user.OptionPacking.displayName = 'proto.tasty.api.v1.user.OptionPacking';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.tasty.api.v1.user.Item = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.tasty.api.v1.user.Item.repeatedFields_, null);
};
goog.inherits(proto.tasty.api.v1.user.Item, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.tasty.api.v1.user.Item.displayName = 'proto.tasty.api.v1.user.Item';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.tasty.api.v1.user.Image = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.tasty.api.v1.user.Image, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.tasty.api.v1.user.Image.displayName = 'proto.tasty.api.v1.user.Image';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.tasty.api.v1.user.FreshnessDate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.tasty.api.v1.user.FreshnessDate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.tasty.api.v1.user.FreshnessDate.displayName = 'proto.tasty.api.v1.user.FreshnessDate';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.tasty.api.v1.user.User = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.tasty.api.v1.user.User, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.tasty.api.v1.user.User.displayName = 'proto.tasty.api.v1.user.User';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.tasty.api.v1.user.ShippingAddress = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.tasty.api.v1.user.ShippingAddress, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.tasty.api.v1.user.ShippingAddress.displayName = 'proto.tasty.api.v1.user.ShippingAddress';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.tasty.api.v1.user.FavoriteItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.tasty.api.v1.user.FavoriteItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.tasty.api.v1.user.FavoriteItem.displayName = 'proto.tasty.api.v1.user.FavoriteItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.tasty.api.v1.user.UserAddress = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.tasty.api.v1.user.UserAddress, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.tasty.api.v1.user.UserAddress.displayName = 'proto.tasty.api.v1.user.UserAddress';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.tasty.api.v1.user.Order.prototype.toObject = function(opt_includeInstance) {
  return proto.tasty.api.v1.user.Order.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.tasty.api.v1.user.Order} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tasty.api.v1.user.Order.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.tasty.api.v1.user.Order}
 */
proto.tasty.api.v1.user.Order.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.tasty.api.v1.user.Order;
  return proto.tasty.api.v1.user.Order.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.tasty.api.v1.user.Order} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.tasty.api.v1.user.Order}
 */
proto.tasty.api.v1.user.Order.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.tasty.api.v1.user.Order.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.tasty.api.v1.user.Order.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.tasty.api.v1.user.Order} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tasty.api.v1.user.Order.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.tasty.api.v1.user.ItemReview.prototype.toObject = function(opt_includeInstance) {
  return proto.tasty.api.v1.user.ItemReview.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.tasty.api.v1.user.ItemReview} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tasty.api.v1.user.ItemReview.toObject = function(includeInstance, msg) {
  var f, obj = {
    count: jspb.Message.getFieldWithDefault(msg, 2, 0),
    comment: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.tasty.api.v1.user.ItemReview}
 */
proto.tasty.api.v1.user.ItemReview.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.tasty.api.v1.user.ItemReview;
  return proto.tasty.api.v1.user.ItemReview.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.tasty.api.v1.user.ItemReview} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.tasty.api.v1.user.ItemReview}
 */
proto.tasty.api.v1.user.ItemReview.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCount(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setComment(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.tasty.api.v1.user.ItemReview.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.tasty.api.v1.user.ItemReview.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.tasty.api.v1.user.ItemReview} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tasty.api.v1.user.ItemReview.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCount();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getComment();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional int64 count = 2;
 * @return {number}
 */
proto.tasty.api.v1.user.ItemReview.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.tasty.api.v1.user.ItemReview} returns this
 */
proto.tasty.api.v1.user.ItemReview.prototype.setCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string comment = 3;
 * @return {string}
 */
proto.tasty.api.v1.user.ItemReview.prototype.getComment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.tasty.api.v1.user.ItemReview} returns this
 */
proto.tasty.api.v1.user.ItemReview.prototype.setComment = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.tasty.api.v1.user.Shop.prototype.toObject = function(opt_includeInstance) {
  return proto.tasty.api.v1.user.Shop.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.tasty.api.v1.user.Shop} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tasty.api.v1.user.Shop.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    images: (f = msg.getImages()) && proto.tasty.api.v1.user.Image.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.tasty.api.v1.user.Shop}
 */
proto.tasty.api.v1.user.Shop.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.tasty.api.v1.user.Shop;
  return proto.tasty.api.v1.user.Shop.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.tasty.api.v1.user.Shop} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.tasty.api.v1.user.Shop}
 */
proto.tasty.api.v1.user.Shop.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 7:
      var value = new proto.tasty.api.v1.user.Image;
      reader.readMessage(value,proto.tasty.api.v1.user.Image.deserializeBinaryFromReader);
      msg.setImages(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.tasty.api.v1.user.Shop.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.tasty.api.v1.user.Shop.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.tasty.api.v1.user.Shop} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tasty.api.v1.user.Shop.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getImages();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.tasty.api.v1.user.Image.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 ID = 1;
 * @return {number}
 */
proto.tasty.api.v1.user.Shop.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.tasty.api.v1.user.Shop} returns this
 */
proto.tasty.api.v1.user.Shop.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.tasty.api.v1.user.Shop.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.tasty.api.v1.user.Shop} returns this
 */
proto.tasty.api.v1.user.Shop.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Image images = 7;
 * @return {?proto.tasty.api.v1.user.Image}
 */
proto.tasty.api.v1.user.Shop.prototype.getImages = function() {
  return /** @type{?proto.tasty.api.v1.user.Image} */ (
    jspb.Message.getWrapperField(this, proto.tasty.api.v1.user.Image, 7));
};


/**
 * @param {?proto.tasty.api.v1.user.Image|undefined} value
 * @return {!proto.tasty.api.v1.user.Shop} returns this
*/
proto.tasty.api.v1.user.Shop.prototype.setImages = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.tasty.api.v1.user.Shop} returns this
 */
proto.tasty.api.v1.user.Shop.prototype.clearImages = function() {
  return this.setImages(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.tasty.api.v1.user.Shop.prototype.hasImages = function() {
  return jspb.Message.getField(this, 7) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.tasty.api.v1.user.OptionPacking.prototype.toObject = function(opt_includeInstance) {
  return proto.tasty.api.v1.user.OptionPacking.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.tasty.api.v1.user.OptionPacking} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tasty.api.v1.user.OptionPacking.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    price: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.tasty.api.v1.user.OptionPacking}
 */
proto.tasty.api.v1.user.OptionPacking.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.tasty.api.v1.user.OptionPacking;
  return proto.tasty.api.v1.user.OptionPacking.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.tasty.api.v1.user.OptionPacking} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.tasty.api.v1.user.OptionPacking}
 */
proto.tasty.api.v1.user.OptionPacking.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPrice(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.tasty.api.v1.user.OptionPacking.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.tasty.api.v1.user.OptionPacking.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.tasty.api.v1.user.OptionPacking} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tasty.api.v1.user.OptionPacking.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPrice();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.tasty.api.v1.user.OptionPacking.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.tasty.api.v1.user.OptionPacking} returns this
 */
proto.tasty.api.v1.user.OptionPacking.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 price = 3;
 * @return {number}
 */
proto.tasty.api.v1.user.OptionPacking.prototype.getPrice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.tasty.api.v1.user.OptionPacking} returns this
 */
proto.tasty.api.v1.user.OptionPacking.prototype.setPrice = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.tasty.api.v1.user.Item.repeatedFields_ = [7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.tasty.api.v1.user.Item.prototype.toObject = function(opt_includeInstance) {
  return proto.tasty.api.v1.user.Item.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.tasty.api.v1.user.Item} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tasty.api.v1.user.Item.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    price: jspb.Message.getFieldWithDefault(msg, 3, 0),
    deliveryPrice: jspb.Message.getFieldWithDefault(msg, 4, 0),
    deliveryStart: jspb.Message.getFieldWithDefault(msg, 5, ""),
    deliveryEnd: jspb.Message.getFieldWithDefault(msg, 6, ""),
    imagesList: jspb.Message.toObjectList(msg.getImagesList(),
    proto.tasty.api.v1.user.Image.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.tasty.api.v1.user.Item}
 */
proto.tasty.api.v1.user.Item.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.tasty.api.v1.user.Item;
  return proto.tasty.api.v1.user.Item.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.tasty.api.v1.user.Item} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.tasty.api.v1.user.Item}
 */
proto.tasty.api.v1.user.Item.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPrice(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDeliveryPrice(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeliveryStart(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeliveryEnd(value);
      break;
    case 7:
      var value = new proto.tasty.api.v1.user.Image;
      reader.readMessage(value,proto.tasty.api.v1.user.Image.deserializeBinaryFromReader);
      msg.addImages(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.tasty.api.v1.user.Item.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.tasty.api.v1.user.Item.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.tasty.api.v1.user.Item} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tasty.api.v1.user.Item.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPrice();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getDeliveryPrice();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getDeliveryStart();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getDeliveryEnd();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getImagesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.tasty.api.v1.user.Image.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 ID = 1;
 * @return {number}
 */
proto.tasty.api.v1.user.Item.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.tasty.api.v1.user.Item} returns this
 */
proto.tasty.api.v1.user.Item.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.tasty.api.v1.user.Item.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.tasty.api.v1.user.Item} returns this
 */
proto.tasty.api.v1.user.Item.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 price = 3;
 * @return {number}
 */
proto.tasty.api.v1.user.Item.prototype.getPrice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.tasty.api.v1.user.Item} returns this
 */
proto.tasty.api.v1.user.Item.prototype.setPrice = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 delivery_price = 4;
 * @return {number}
 */
proto.tasty.api.v1.user.Item.prototype.getDeliveryPrice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.tasty.api.v1.user.Item} returns this
 */
proto.tasty.api.v1.user.Item.prototype.setDeliveryPrice = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string delivery_start = 5;
 * @return {string}
 */
proto.tasty.api.v1.user.Item.prototype.getDeliveryStart = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.tasty.api.v1.user.Item} returns this
 */
proto.tasty.api.v1.user.Item.prototype.setDeliveryStart = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string delivery_end = 6;
 * @return {string}
 */
proto.tasty.api.v1.user.Item.prototype.getDeliveryEnd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.tasty.api.v1.user.Item} returns this
 */
proto.tasty.api.v1.user.Item.prototype.setDeliveryEnd = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * repeated Image images = 7;
 * @return {!Array<!proto.tasty.api.v1.user.Image>}
 */
proto.tasty.api.v1.user.Item.prototype.getImagesList = function() {
  return /** @type{!Array<!proto.tasty.api.v1.user.Image>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.tasty.api.v1.user.Image, 7));
};


/**
 * @param {!Array<!proto.tasty.api.v1.user.Image>} value
 * @return {!proto.tasty.api.v1.user.Item} returns this
*/
proto.tasty.api.v1.user.Item.prototype.setImagesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.tasty.api.v1.user.Image=} opt_value
 * @param {number=} opt_index
 * @return {!proto.tasty.api.v1.user.Image}
 */
proto.tasty.api.v1.user.Item.prototype.addImages = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.tasty.api.v1.user.Image, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.tasty.api.v1.user.Item} returns this
 */
proto.tasty.api.v1.user.Item.prototype.clearImagesList = function() {
  return this.setImagesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.tasty.api.v1.user.Image.prototype.toObject = function(opt_includeInstance) {
  return proto.tasty.api.v1.user.Image.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.tasty.api.v1.user.Image} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tasty.api.v1.user.Image.toObject = function(includeInstance, msg) {
  var f, obj = {
    url: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    displayOrder: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.tasty.api.v1.user.Image}
 */
proto.tasty.api.v1.user.Image.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.tasty.api.v1.user.Image;
  return proto.tasty.api.v1.user.Image.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.tasty.api.v1.user.Image} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.tasty.api.v1.user.Image}
 */
proto.tasty.api.v1.user.Image.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDisplayOrder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.tasty.api.v1.user.Image.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.tasty.api.v1.user.Image.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.tasty.api.v1.user.Image} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tasty.api.v1.user.Image.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDisplayOrder();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
};


/**
 * optional string url = 1;
 * @return {string}
 */
proto.tasty.api.v1.user.Image.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.tasty.api.v1.user.Image} returns this
 */
proto.tasty.api.v1.user.Image.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.tasty.api.v1.user.Image.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.tasty.api.v1.user.Image} returns this
 */
proto.tasty.api.v1.user.Image.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint32 display_order = 3;
 * @return {number}
 */
proto.tasty.api.v1.user.Image.prototype.getDisplayOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.tasty.api.v1.user.Image} returns this
 */
proto.tasty.api.v1.user.Image.prototype.setDisplayOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.tasty.api.v1.user.FreshnessDate.prototype.toObject = function(opt_includeInstance) {
  return proto.tasty.api.v1.user.FreshnessDate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.tasty.api.v1.user.FreshnessDate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tasty.api.v1.user.FreshnessDate.toObject = function(includeInstance, msg) {
  var f, obj = {
    dateStart: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dateEnd: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.tasty.api.v1.user.FreshnessDate}
 */
proto.tasty.api.v1.user.FreshnessDate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.tasty.api.v1.user.FreshnessDate;
  return proto.tasty.api.v1.user.FreshnessDate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.tasty.api.v1.user.FreshnessDate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.tasty.api.v1.user.FreshnessDate}
 */
proto.tasty.api.v1.user.FreshnessDate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateStart(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateEnd(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.tasty.api.v1.user.FreshnessDate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.tasty.api.v1.user.FreshnessDate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.tasty.api.v1.user.FreshnessDate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tasty.api.v1.user.FreshnessDate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDateStart();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDateEnd();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string date_start = 1;
 * @return {string}
 */
proto.tasty.api.v1.user.FreshnessDate.prototype.getDateStart = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.tasty.api.v1.user.FreshnessDate} returns this
 */
proto.tasty.api.v1.user.FreshnessDate.prototype.setDateStart = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string date_end = 2;
 * @return {string}
 */
proto.tasty.api.v1.user.FreshnessDate.prototype.getDateEnd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.tasty.api.v1.user.FreshnessDate} returns this
 */
proto.tasty.api.v1.user.FreshnessDate.prototype.setDateEnd = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.tasty.api.v1.user.User.prototype.toObject = function(opt_includeInstance) {
  return proto.tasty.api.v1.user.User.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.tasty.api.v1.user.User} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tasty.api.v1.user.User.toObject = function(includeInstance, msg) {
  var f, obj = {
    email: jspb.Message.getFieldWithDefault(msg, 1, ""),
    password: jspb.Message.getFieldWithDefault(msg, 2, ""),
    nickname: jspb.Message.getFieldWithDefault(msg, 3, ""),
    firstName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    lastName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    firstKana: jspb.Message.getFieldWithDefault(msg, 6, ""),
    lastKana: jspb.Message.getFieldWithDefault(msg, 7, ""),
    zipCode: jspb.Message.getFieldWithDefault(msg, 9, ""),
    prefecture: jspb.Message.getFieldWithDefault(msg, 10, ""),
    municipality: jspb.Message.getFieldWithDefault(msg, 11, ""),
    address1: jspb.Message.getFieldWithDefault(msg, 12, ""),
    address2: jspb.Message.getFieldWithDefault(msg, 13, ""),
    phoneNumber: jspb.Message.getFieldWithDefault(msg, 14, ""),
    birthday: jspb.Message.getFieldWithDefault(msg, 15, ""),
    gender: jspb.Message.getFieldWithDefault(msg, 16, 0),
    status: jspb.Message.getFieldWithDefault(msg, 17, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.tasty.api.v1.user.User}
 */
proto.tasty.api.v1.user.User.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.tasty.api.v1.user.User;
  return proto.tasty.api.v1.user.User.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.tasty.api.v1.user.User} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.tasty.api.v1.user.User}
 */
proto.tasty.api.v1.user.User.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setNickname(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstKana(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastKana(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setZipCode(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrefecture(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setMunicipality(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress1(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress2(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoneNumber(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setBirthday(value);
      break;
    case 16:
      var value = /** @type {!proto.tasty.api.v1.common.Gender.Status} */ (reader.readEnum());
      msg.setGender(value);
      break;
    case 17:
      var value = /** @type {!proto.tasty.api.v1.common.UserStatus.Status} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.tasty.api.v1.user.User.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.tasty.api.v1.user.User.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.tasty.api.v1.user.User} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tasty.api.v1.user.User.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getNickname();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getFirstName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getLastName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getFirstKana();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getLastKana();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getZipCode();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getPrefecture();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getMunicipality();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getAddress1();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getAddress2();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getPhoneNumber();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getBirthday();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getGender();
  if (f !== 0.0) {
    writer.writeEnum(
      16,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      17,
      f
    );
  }
};


/**
 * optional string email = 1;
 * @return {string}
 */
proto.tasty.api.v1.user.User.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.tasty.api.v1.user.User} returns this
 */
proto.tasty.api.v1.user.User.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string password = 2;
 * @return {string}
 */
proto.tasty.api.v1.user.User.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.tasty.api.v1.user.User} returns this
 */
proto.tasty.api.v1.user.User.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string nickname = 3;
 * @return {string}
 */
proto.tasty.api.v1.user.User.prototype.getNickname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.tasty.api.v1.user.User} returns this
 */
proto.tasty.api.v1.user.User.prototype.setNickname = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string first_name = 4;
 * @return {string}
 */
proto.tasty.api.v1.user.User.prototype.getFirstName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.tasty.api.v1.user.User} returns this
 */
proto.tasty.api.v1.user.User.prototype.setFirstName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string last_name = 5;
 * @return {string}
 */
proto.tasty.api.v1.user.User.prototype.getLastName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.tasty.api.v1.user.User} returns this
 */
proto.tasty.api.v1.user.User.prototype.setLastName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string first_kana = 6;
 * @return {string}
 */
proto.tasty.api.v1.user.User.prototype.getFirstKana = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.tasty.api.v1.user.User} returns this
 */
proto.tasty.api.v1.user.User.prototype.setFirstKana = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string last_kana = 7;
 * @return {string}
 */
proto.tasty.api.v1.user.User.prototype.getLastKana = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.tasty.api.v1.user.User} returns this
 */
proto.tasty.api.v1.user.User.prototype.setLastKana = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string zip_code = 9;
 * @return {string}
 */
proto.tasty.api.v1.user.User.prototype.getZipCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.tasty.api.v1.user.User} returns this
 */
proto.tasty.api.v1.user.User.prototype.setZipCode = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string prefecture = 10;
 * @return {string}
 */
proto.tasty.api.v1.user.User.prototype.getPrefecture = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.tasty.api.v1.user.User} returns this
 */
proto.tasty.api.v1.user.User.prototype.setPrefecture = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string municipality = 11;
 * @return {string}
 */
proto.tasty.api.v1.user.User.prototype.getMunicipality = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.tasty.api.v1.user.User} returns this
 */
proto.tasty.api.v1.user.User.prototype.setMunicipality = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string address1 = 12;
 * @return {string}
 */
proto.tasty.api.v1.user.User.prototype.getAddress1 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.tasty.api.v1.user.User} returns this
 */
proto.tasty.api.v1.user.User.prototype.setAddress1 = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string address2 = 13;
 * @return {string}
 */
proto.tasty.api.v1.user.User.prototype.getAddress2 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.tasty.api.v1.user.User} returns this
 */
proto.tasty.api.v1.user.User.prototype.setAddress2 = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string phone_number = 14;
 * @return {string}
 */
proto.tasty.api.v1.user.User.prototype.getPhoneNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.tasty.api.v1.user.User} returns this
 */
proto.tasty.api.v1.user.User.prototype.setPhoneNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string birthday = 15;
 * @return {string}
 */
proto.tasty.api.v1.user.User.prototype.getBirthday = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.tasty.api.v1.user.User} returns this
 */
proto.tasty.api.v1.user.User.prototype.setBirthday = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional tasty.api.v1.common.Gender.Status gender = 16;
 * @return {!proto.tasty.api.v1.common.Gender.Status}
 */
proto.tasty.api.v1.user.User.prototype.getGender = function() {
  return /** @type {!proto.tasty.api.v1.common.Gender.Status} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {!proto.tasty.api.v1.common.Gender.Status} value
 * @return {!proto.tasty.api.v1.user.User} returns this
 */
proto.tasty.api.v1.user.User.prototype.setGender = function(value) {
  return jspb.Message.setProto3EnumField(this, 16, value);
};


/**
 * optional tasty.api.v1.common.UserStatus.Status status = 17;
 * @return {!proto.tasty.api.v1.common.UserStatus.Status}
 */
proto.tasty.api.v1.user.User.prototype.getStatus = function() {
  return /** @type {!proto.tasty.api.v1.common.UserStatus.Status} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {!proto.tasty.api.v1.common.UserStatus.Status} value
 * @return {!proto.tasty.api.v1.user.User} returns this
 */
proto.tasty.api.v1.user.User.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 17, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.tasty.api.v1.user.ShippingAddress.prototype.toObject = function(opt_includeInstance) {
  return proto.tasty.api.v1.user.ShippingAddress.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.tasty.api.v1.user.ShippingAddress} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tasty.api.v1.user.ShippingAddress.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    zipCode: jspb.Message.getFieldWithDefault(msg, 3, ""),
    regionId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    municipality: jspb.Message.getFieldWithDefault(msg, 5, ""),
    address1: jspb.Message.getFieldWithDefault(msg, 6, ""),
    address2: jspb.Message.getFieldWithDefault(msg, 7, ""),
    phoneNumber: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.tasty.api.v1.user.ShippingAddress}
 */
proto.tasty.api.v1.user.ShippingAddress.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.tasty.api.v1.user.ShippingAddress;
  return proto.tasty.api.v1.user.ShippingAddress.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.tasty.api.v1.user.ShippingAddress} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.tasty.api.v1.user.ShippingAddress}
 */
proto.tasty.api.v1.user.ShippingAddress.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setZipCode(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRegionId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setMunicipality(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress1(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress2(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoneNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.tasty.api.v1.user.ShippingAddress.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.tasty.api.v1.user.ShippingAddress.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.tasty.api.v1.user.ShippingAddress} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tasty.api.v1.user.ShippingAddress.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getZipCode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getRegionId();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getMunicipality();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getAddress1();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getAddress2();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getPhoneNumber();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional int64 ID = 1;
 * @return {number}
 */
proto.tasty.api.v1.user.ShippingAddress.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.tasty.api.v1.user.ShippingAddress} returns this
 */
proto.tasty.api.v1.user.ShippingAddress.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.tasty.api.v1.user.ShippingAddress.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.tasty.api.v1.user.ShippingAddress} returns this
 */
proto.tasty.api.v1.user.ShippingAddress.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string zip_code = 3;
 * @return {string}
 */
proto.tasty.api.v1.user.ShippingAddress.prototype.getZipCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.tasty.api.v1.user.ShippingAddress} returns this
 */
proto.tasty.api.v1.user.ShippingAddress.prototype.setZipCode = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 region_id = 4;
 * @return {number}
 */
proto.tasty.api.v1.user.ShippingAddress.prototype.getRegionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.tasty.api.v1.user.ShippingAddress} returns this
 */
proto.tasty.api.v1.user.ShippingAddress.prototype.setRegionId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string municipality = 5;
 * @return {string}
 */
proto.tasty.api.v1.user.ShippingAddress.prototype.getMunicipality = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.tasty.api.v1.user.ShippingAddress} returns this
 */
proto.tasty.api.v1.user.ShippingAddress.prototype.setMunicipality = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string address1 = 6;
 * @return {string}
 */
proto.tasty.api.v1.user.ShippingAddress.prototype.getAddress1 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.tasty.api.v1.user.ShippingAddress} returns this
 */
proto.tasty.api.v1.user.ShippingAddress.prototype.setAddress1 = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string address2 = 7;
 * @return {string}
 */
proto.tasty.api.v1.user.ShippingAddress.prototype.getAddress2 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.tasty.api.v1.user.ShippingAddress} returns this
 */
proto.tasty.api.v1.user.ShippingAddress.prototype.setAddress2 = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string phone_number = 8;
 * @return {string}
 */
proto.tasty.api.v1.user.ShippingAddress.prototype.getPhoneNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.tasty.api.v1.user.ShippingAddress} returns this
 */
proto.tasty.api.v1.user.ShippingAddress.prototype.setPhoneNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.tasty.api.v1.user.FavoriteItem.prototype.toObject = function(opt_includeInstance) {
  return proto.tasty.api.v1.user.FavoriteItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.tasty.api.v1.user.FavoriteItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tasty.api.v1.user.FavoriteItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    itemName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    shopid: jspb.Message.getFieldWithDefault(msg, 3, 0),
    shopName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    itemPrice: jspb.Message.getFieldWithDefault(msg, 5, 0),
    itemImagePath: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.tasty.api.v1.user.FavoriteItem}
 */
proto.tasty.api.v1.user.FavoriteItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.tasty.api.v1.user.FavoriteItem;
  return proto.tasty.api.v1.user.FavoriteItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.tasty.api.v1.user.FavoriteItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.tasty.api.v1.user.FavoriteItem}
 */
proto.tasty.api.v1.user.FavoriteItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setItemid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setItemName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setShopid(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setShopName(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setItemPrice(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setItemImagePath(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.tasty.api.v1.user.FavoriteItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.tasty.api.v1.user.FavoriteItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.tasty.api.v1.user.FavoriteItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tasty.api.v1.user.FavoriteItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getItemName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getShopid();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getShopName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getItemPrice();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getItemImagePath();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional int64 itemID = 1;
 * @return {number}
 */
proto.tasty.api.v1.user.FavoriteItem.prototype.getItemid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.tasty.api.v1.user.FavoriteItem} returns this
 */
proto.tasty.api.v1.user.FavoriteItem.prototype.setItemid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string item_name = 2;
 * @return {string}
 */
proto.tasty.api.v1.user.FavoriteItem.prototype.getItemName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.tasty.api.v1.user.FavoriteItem} returns this
 */
proto.tasty.api.v1.user.FavoriteItem.prototype.setItemName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 shopID = 3;
 * @return {number}
 */
proto.tasty.api.v1.user.FavoriteItem.prototype.getShopid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.tasty.api.v1.user.FavoriteItem} returns this
 */
proto.tasty.api.v1.user.FavoriteItem.prototype.setShopid = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string shop_name = 4;
 * @return {string}
 */
proto.tasty.api.v1.user.FavoriteItem.prototype.getShopName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.tasty.api.v1.user.FavoriteItem} returns this
 */
proto.tasty.api.v1.user.FavoriteItem.prototype.setShopName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int64 item_price = 5;
 * @return {number}
 */
proto.tasty.api.v1.user.FavoriteItem.prototype.getItemPrice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.tasty.api.v1.user.FavoriteItem} returns this
 */
proto.tasty.api.v1.user.FavoriteItem.prototype.setItemPrice = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string item_image_path = 6;
 * @return {string}
 */
proto.tasty.api.v1.user.FavoriteItem.prototype.getItemImagePath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.tasty.api.v1.user.FavoriteItem} returns this
 */
proto.tasty.api.v1.user.FavoriteItem.prototype.setItemImagePath = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.tasty.api.v1.user.UserAddress.prototype.toObject = function(opt_includeInstance) {
  return proto.tasty.api.v1.user.UserAddress.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.tasty.api.v1.user.UserAddress} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tasty.api.v1.user.UserAddress.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    country: jspb.Message.getFieldWithDefault(msg, 3, ""),
    firstName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    lastName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    firstKana: jspb.Message.getFieldWithDefault(msg, 6, ""),
    lastKana: jspb.Message.getFieldWithDefault(msg, 7, ""),
    zipCode: jspb.Message.getFieldWithDefault(msg, 8, ""),
    prefecture: jspb.Message.getFieldWithDefault(msg, 9, ""),
    municipality: jspb.Message.getFieldWithDefault(msg, 10, ""),
    address1: jspb.Message.getFieldWithDefault(msg, 11, ""),
    address2: jspb.Message.getFieldWithDefault(msg, 12, ""),
    building: jspb.Message.getFieldWithDefault(msg, 13, ""),
    phoneNumber: jspb.Message.getFieldWithDefault(msg, 14, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.tasty.api.v1.user.UserAddress}
 */
proto.tasty.api.v1.user.UserAddress.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.tasty.api.v1.user.UserAddress;
  return proto.tasty.api.v1.user.UserAddress.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.tasty.api.v1.user.UserAddress} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.tasty.api.v1.user.UserAddress}
 */
proto.tasty.api.v1.user.UserAddress.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountry(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstKana(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastKana(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setZipCode(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrefecture(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setMunicipality(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress1(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress2(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setBuilding(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoneNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.tasty.api.v1.user.UserAddress.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.tasty.api.v1.user.UserAddress.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.tasty.api.v1.user.UserAddress} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tasty.api.v1.user.UserAddress.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCountry();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getFirstName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getLastName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getFirstKana();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getLastKana();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getZipCode();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getPrefecture();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getMunicipality();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getAddress1();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getAddress2();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getBuilding();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getPhoneNumber();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
};


/**
 * optional int64 ID = 1;
 * @return {number}
 */
proto.tasty.api.v1.user.UserAddress.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.tasty.api.v1.user.UserAddress} returns this
 */
proto.tasty.api.v1.user.UserAddress.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.tasty.api.v1.user.UserAddress.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.tasty.api.v1.user.UserAddress} returns this
 */
proto.tasty.api.v1.user.UserAddress.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string country = 3;
 * @return {string}
 */
proto.tasty.api.v1.user.UserAddress.prototype.getCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.tasty.api.v1.user.UserAddress} returns this
 */
proto.tasty.api.v1.user.UserAddress.prototype.setCountry = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string first_name = 4;
 * @return {string}
 */
proto.tasty.api.v1.user.UserAddress.prototype.getFirstName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.tasty.api.v1.user.UserAddress} returns this
 */
proto.tasty.api.v1.user.UserAddress.prototype.setFirstName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string last_name = 5;
 * @return {string}
 */
proto.tasty.api.v1.user.UserAddress.prototype.getLastName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.tasty.api.v1.user.UserAddress} returns this
 */
proto.tasty.api.v1.user.UserAddress.prototype.setLastName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string first_kana = 6;
 * @return {string}
 */
proto.tasty.api.v1.user.UserAddress.prototype.getFirstKana = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.tasty.api.v1.user.UserAddress} returns this
 */
proto.tasty.api.v1.user.UserAddress.prototype.setFirstKana = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string last_kana = 7;
 * @return {string}
 */
proto.tasty.api.v1.user.UserAddress.prototype.getLastKana = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.tasty.api.v1.user.UserAddress} returns this
 */
proto.tasty.api.v1.user.UserAddress.prototype.setLastKana = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string zip_code = 8;
 * @return {string}
 */
proto.tasty.api.v1.user.UserAddress.prototype.getZipCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.tasty.api.v1.user.UserAddress} returns this
 */
proto.tasty.api.v1.user.UserAddress.prototype.setZipCode = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string prefecture = 9;
 * @return {string}
 */
proto.tasty.api.v1.user.UserAddress.prototype.getPrefecture = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.tasty.api.v1.user.UserAddress} returns this
 */
proto.tasty.api.v1.user.UserAddress.prototype.setPrefecture = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string municipality = 10;
 * @return {string}
 */
proto.tasty.api.v1.user.UserAddress.prototype.getMunicipality = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.tasty.api.v1.user.UserAddress} returns this
 */
proto.tasty.api.v1.user.UserAddress.prototype.setMunicipality = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string address1 = 11;
 * @return {string}
 */
proto.tasty.api.v1.user.UserAddress.prototype.getAddress1 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.tasty.api.v1.user.UserAddress} returns this
 */
proto.tasty.api.v1.user.UserAddress.prototype.setAddress1 = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string address2 = 12;
 * @return {string}
 */
proto.tasty.api.v1.user.UserAddress.prototype.getAddress2 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.tasty.api.v1.user.UserAddress} returns this
 */
proto.tasty.api.v1.user.UserAddress.prototype.setAddress2 = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string building = 13;
 * @return {string}
 */
proto.tasty.api.v1.user.UserAddress.prototype.getBuilding = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.tasty.api.v1.user.UserAddress} returns this
 */
proto.tasty.api.v1.user.UserAddress.prototype.setBuilding = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string phone_number = 14;
 * @return {string}
 */
proto.tasty.api.v1.user.UserAddress.prototype.getPhoneNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.tasty.api.v1.user.UserAddress} returns this
 */
proto.tasty.api.v1.user.UserAddress.prototype.setPhoneNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


goog.object.extend(exports, proto.tasty.api.v1.user);
