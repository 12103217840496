import axios, { AxiosError } from 'axios';

import { ErrorResponse } from '../pb/api/error_pb';

export function getCookie(name: string) {
  if (typeof document !== 'undefined' && document.cookie) {
    const match = document.cookie.match(new RegExp(`(^| )${name}=([^;]+)`));
    if (match) return match[2];
  }
  return '';
}

const headers = {
  'Content-Type': 'application/octet-stream',
};

export const axiosClient = axios.create({
  baseURL: process.env.ENDPOINT || 'https://dev-tasty.work',
  timeout: 3000,
  headers,
  responseType: 'arraybuffer',
});

axiosClient.interceptors.response.use(
  (res) => res,
  (err: AxiosError<Uint8Array>) => {
    console.error(err);
    if (err.response) {
      const errResponse = ErrorResponse.deserializeBinary(err.response?.data);
      throw errResponse;
    }
    const errResponse = new ErrorResponse();
    errResponse.setError('Unexpected Error');
    throw errResponse;
  }
);

axiosClient.interceptors.request.use(
  (config) => {
    if (getCookie('token') && config && config.headers) {
      // check authorization and set header
      config.headers.Authorization = `Bearer ${getCookie('token')}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);
