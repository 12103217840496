import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ComponentProps } from 'react';

import { useAppDispatch, useAppSelector } from '../hooks';

export const remindmeSlice = createSlice({
  name: 'remindme',
  initialState: { remindme: false },
  reducers: {
    toggleRemindme: (state, action: PayloadAction<boolean>) => {
      return { remindme: action.payload };
    },
  },
});

export const { toggleRemindme } = remindmeSlice.actions;

export const useRemindme = () => {
  const remindme = useAppSelector((rootState) => rootState.remindme.remindme);
  const dispatch = useAppDispatch();

  const onChangeRemindme: ComponentProps<'input'>['onChange'] = (e) => {
    dispatch(toggleRemindme(e.target.checked));
  };
  return { remindme, onChangeRemindme };
};
