import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { RootState } from '../store';

// 購入者情報登録フォーム用の型
export type CustomerInfo = {
  zipCode: string; // 郵便番号
  prefecture: string; // 都道府県
  municipality: string; // 市区町村
  address1: string; // 番地
  address2: string; // 建物名
  phoneNumber: string; // 電話番号
};

// お届け先情報登録フォーム用の型
export type DeliveryAddressInfo = {
  title: string; // 宛名(任意の人物名や会社名、団体名 etc )
  zipCode: string; // 郵便番号
  prefecture: string; // 都道府県
  municipality: string; // 市区町村
  address1: string; // 番地
  address2: string; // 建物名
  phoneNumber: string; // 電話番号
};

type State = {
  customerInfo: CustomerInfo;
  deliveryAddressInfo: DeliveryAddressInfo;
};

export const initialState: State = {
  customerInfo: {
    zipCode: '',
    prefecture: '',
    municipality: '',
    address1: '',
    address2: '',
    phoneNumber: '',
  },
  deliveryAddressInfo: {
    title: '',
    zipCode: '',
    prefecture: '',
    municipality: '',
    address1: '',
    address2: '',
    phoneNumber: '',
  },
};

export const addressFormSlice = createSlice({
  name: 'addressFormState',
  initialState,
  reducers: {
    setCustomerInfo: (state, action: PayloadAction<CustomerInfo>) => {
      state.customerInfo = action.payload;
    },
    setDeliveryAddressInfo: (state, action: PayloadAction<DeliveryAddressInfo>) => {
      state.deliveryAddressInfo = action.payload;
    },
    initCustomerInfo: (state) => {
      state.customerInfo = initialState.customerInfo;
    },
    initDeliveryAddressInfo: (state) => {
      state.deliveryAddressInfo = initialState.deliveryAddressInfo;
    },
  },
});

export const {
  setCustomerInfo,
  setDeliveryAddressInfo,
  initCustomerInfo,
  initDeliveryAddressInfo,
} = addressFormSlice.actions;

export const addressFormState = (state: RootState) => state.addressFormState;

export default addressFormSlice;
