import { useCookies } from 'react-cookie';

import { axiosClient } from '@api/axiosClient';
import { useSuspenseFetch } from '@hooks/useSuspenseFetch';
import { ErrorResponse } from '@pb/api/error_pb';
import { DetailRequest, DetailResponse } from '@pb/api/v1/user/account/account_pb';

const endpoint = '/api/v1/user/account/detail';

export const useUser = () => {
  const [, , removeCookie] = useCookies(['token']);
  const { data } = useSuspenseFetch<DetailResponse.AsObject['user'], ErrorResponse>(
    endpoint,
    async () => {
      try {
        const detailRequest = new DetailRequest();
        const res = await axiosClient.post(endpoint, detailRequest.serializeBinary());
        const detailResponse = DetailResponse.deserializeBinary(res.data);
        return detailResponse.toObject().user;
      } catch (e) {
        if (
          typeof location !== 'undefined' &&
          ['/login', '/official-signup'].some((_) => location.pathname.includes(_))
        ) {
          console.error(e);
          console.error('account loaded');
          return undefined as never;
        }
        return undefined as never;
      }
    },
    {
      suspense: true,
      onError: (_) => {
        return undefined;
      },
    }
  );

  return data;
};
