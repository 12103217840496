import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';

import { addressFormSlice } from './slices/addressFormSlice';
import { cardSlice } from './slices/cardSlice';
import { cartParamSlice } from './slices/cartParamSlice';
import { eGiftCardSlice } from './slices/egiftCardSlice';
import { listRequestSlice } from './slices/listRequestSlice';
import { listResponseSlice } from './slices/listResponseSlice';
import { modalSlice } from './slices/modalSlice';
import { popupSlice } from './slices/popupSlice';
import purchaseFlowSlice from './slices/purchaseFlow';
import { remindmeSlice } from './slices/remindmeSlice';
import { userSlice } from './slices/userSlice';

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['listRequestReducer', 'popupReducer'],
};

const rootReducer = combineReducers({
  listRequestReducer: listRequestSlice.reducer,
  listResponseReducer: listResponseSlice.reducer,
  // productsReducer: productsSlice.reducer,
  // shopsReducer: shopsSlice.reducer,
  userReducer: userSlice.reducer,
  userCarts: cartParamSlice.reducer,
  popupReducer: popupSlice.reducer,
  eGiftCardReducer: eGiftCardSlice.reducer,
  modalState: modalSlice.reducer,
  remindme: remindmeSlice.reducer,
  addressFormState: addressFormSlice.reducer,
  cardSlice: cardSlice.reducer,
  purchaseFlowState: purchaseFlowSlice.reducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
