import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface StateProps {
  cardToken: string;
  maskedCardNumber: string;
}

export const initialState: StateProps = {
  cardToken: '',
  maskedCardNumber: '',
};

export const cardSlice = createSlice({
  name: 'cardSlice',
  initialState,
  reducers: {
    setCardToken: (state, action: PayloadAction<string>) => {
      state.cardToken = action.payload;
    },
    setMaskedCardNumber: (state, action: PayloadAction<string>) => {
      state.maskedCardNumber = action.payload;
    },
  },
});

export const { setCardToken, setMaskedCardNumber } = cardSlice.actions;
