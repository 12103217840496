import { createSlice } from '@reduxjs/toolkit';

import type { RootState } from '../store';

type State = {
  key?: 'top/taste' | 'top/price' | 'top/allergy' | 'top/commit';
  orderAddress: boolean;
  orderAddressEdit: boolean;
};

export const initialState: State = {
  orderAddress: false,
  orderAddressEdit: false,
};

export const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    openTasteModal: (pre) => ({
      ...pre,
      key: 'top/taste',
    }),
    openPriceModal: (pre) => ({
      ...pre,
      key: 'top/price',
    }),
    openAllergyModal: (pre) => ({
      ...pre,
      key: 'top/allergy',
    }),
    openCommitModal: (pre) => ({
      ...pre,
      key: 'top/commit',
    }),
    close: (pre) => ({
      ...pre,
      key: undefined,
    }),
    openAddressModal: (pre) => ({
      ...pre,
      orderAddress: true,
    }),
    closeAddressModal: (pre) => ({
      ...pre,
      orderAddress: false,
    }),
    openAddressEditModal: (pre) => ({
      ...pre,
      orderAddressEdit: true,
    }),
    closeAddressEditModal: (pre) => ({
      ...pre,
      orderAddressEdit: false,
    }),
  },
});

export const {
  close,
  openTasteModal,
  openPriceModal,
  openAllergyModal,
  openCommitModal,
  openAddressModal,
  closeAddressModal,
  openAddressEditModal,
  closeAddressEditModal,
} = modalSlice.actions;

export const modalState = (state: RootState) => state.modalState;

export default modalSlice;
